import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
// import SEO from "../components/seo";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "Prezentacja-prosty-system-CRM.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const PrezentacjaCRM = ({ data }) => {
  return (
    <Layout>
      <br />
      <a href="/Prezentacja-QuickCRM.pdf">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto",
            maxWidth: 600
          }}
          title="Prezentacja CRM"
          alt="Prezentacja CRM"
          fluid={data.zdjecie1.childImageSharp.fluid}
        />
      </a>
      <br />
    </Layout>
  );
};

export default PrezentacjaCRM;
